<template>
  <div class="huankong">
    <div class="top">
      <div class="card card-1">
        <h6>
          分娩舍
        </h6>
        <div class="line-img">
          <img :src="require('./imgs/house.png')" />
          <!-- <img :src="require('./imgs/house_k.png')"/> -->
        </div>
        <div class="bto">
          <div class="it">
            <div class="value">在线</div>
            <div class="text">在线状态</div>
          </div>
          <div class="it">
            <div class="value">在线</div>
            <div class="text">猪舍状态</div>
          </div>
          <div class="it">
            <div class="value">50</div>
            <div class="text">猪只日龄</div>
          </div>
          <div class="it mt">
            <div class="value">5</div>
            <div class="text">通风级别</div>
          </div>
          <div class="it mt">
            <div class="value">23</div>
            <div class="text">室外温度</div>
          </div>
        </div>
      </div>
      <div class="card card-2">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/wen.png')" />
        </div>
        <div class="card-value">31.5℃</div>
        <div class="card-target">目标温度：31.5℃</div>
      </div>
      <div class="card card-3">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/feng.png')" />
        </div>
        <div class="card-value">31.5℃</div>
        <div class="card-target">目标温度：31.5℃</div>
      </div>
      <div class="card card-4">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/shi.png')" />
        </div>
        <div class="card-value">31.5℃</div>
      </div>
      <div class="card card-5">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/er.png')" />
        </div>
        <div class="card-value">31.5℃</div>
      </div>
      <div class="card card-6">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/an.png')" />
        </div>
        <div class="card-value">31.5℃</div>
      </div>
      <div class="card card-7">
        <div class="card-text">温度</div>
        <div class="line-img">
          <img :src="require('./imgs/qi.png')" />
        </div>
        <div class="card-value">31.5℃</div>
      </div>
    </div>
    <div class="chart-wrapper">
      <div class="title">
        <span>今日温度</span>
        <span class="history" @click="openHistory">历史温度</span>
      </div>
      <div class="body">
        <v-chart class="chart" autoresize :option="createOption()" />
      </div>
    </div>
    <temperatureDialog
        :title="temperatureTitle"
        v-model:visible="temperatureVisible"
        v-model:uuid="temperatureUuid"
      />
  </div>
</template>

<script>
import { use, LinearGradientObject } from "echarts/core";
import { CanvasRenderer, LinearGradient } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart, GaugeChart } from "echarts/charts";
import * as echarts from "echarts/core"
use([
  GaugeChart,
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);
import temperatureDialog from "./temperatureCurve";
const colors = [
  // "#FF4C50",
  "#67C23A",
  "#FF9B78",
];
export default {
  components: { VChart, temperatureDialog },
  data() {
    return {
      yData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10],
      yData2: [11, 12, 13, 14, 15, 16, 17, 18, 19, 10, 11],


      temperatureTitle: "历史温度",
      temperatureVisible: false,
      temperatureUuid: "",
    }
  },
  mounted() { },
  methods: {

    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
          // formatter: '{b0}: {c0}<br />{b1}: {c1}'
          // valueFormatter: (value) => '$' + value.toFixed(2)


          // backgroundColor: "rgba(3, 117, 139, 0.7)",
          // borderColor: "rgba(0, 213, 255, 1)",
          // textStyle: {
          //   color: "#fff",
          // },
        },
        legend: {
          icon: "rect",
          right: 30,
          // tooltip: {
          //   show: true,
          // },
          textStyle: {
            fontSize: 16,
            color: "#B4C0D3",
          },
          itemWidth: 34,
          itemHeight: 14,
        },
        grid: {
          left: 0,
          bottom: 20,
          right: 10,
          top: 40,
          containLabel: true,
          show: true,
          backgroundColor: "#0E2454",
          borderColor: "transparent",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow",
          },
          axisTick: {
            alignWithLabel: false,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#B1B2B5",
            },
          },
          nameTextStyle: {
            fontSize: 18,
            color: "#E8F6F8",
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return params.value;
              },
            },
          },
          axisLabel: {
            // interval: 5,
            // minInterval: 1,
            // maxInterval: 6,
            // rotate: 60,
            margin: 15,
          },
          data: this.xData,
        },
        yAxis: [
          {
            type: "value",
            name: "℃",
            min: 10,
            max: 45,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#AAAAAA",
                opacity: 0.36,
              },
            },
            nameTextStyle: {
              fontSize: 18,
              color: "#E8F6F8",
            },
          },
        ],
        series: [
          {
            name: "实际温度",
            type: "line",
            smooth: true,
            data: this.yData,
          },
          {
            name: "目标温度",
            type: "line",
            smooth: true,
            data: this.yData2,
          },
        ],
      };
    },
    openHistory() {
      this.temperatureVisible = true
    },
  }
}
</script>
<style scoped lang="less" src="./index.less"></style>